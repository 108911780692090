import React, { useState, useEffect } from 'react'

import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { partition } from 'lodash'

import "./SessionPrint.css"

import { callApi } from '../services/fetchService'
import SessionPart1 from '../components/SessionPart1';
import SessionPart2 from '../components/SessionPart2';
import SessionPart3 from '../components/SessionPart3';
import Icon from '../components/Icon'

import { Spin, Button, message } from 'antd'



export default () => {

  const { t } = useTranslation()

  let { id } = useParams()

  let history = useHistory()

  const [loading, setLoading] = useState(false)
  const [session, setSession] = useState()

  useEffect(() => {
    setLoading(true)
    callApi({
      endpoint: 'sessions/' + id,
      method: 'GET'
    })
      .then((session) => {
        session.interview_duration = session.interview_start && session.interview_end && moment.duration(moment(session.interview_start).diff(moment(session.interview_end)))
        if (session.alters) {
          session.alters.forEach((alter => {
            alter.role = alter.role || alter.link_type || alter.function
          }))
          session.alters = partition(session.alters, { type: 'medical' })
        }
        setSession(session)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
        message.error(t('sessionPrint.unableToLoad'))
      })
  }, [id, t])

  return (
    <Spin size="large" spinning={loading} style={{ marginTop: "30px" }}>
      {!loading && <div className="back-button">
        <Button type="primary" size="large" onClick={window.print} ><Icon icon="print" fixedWidth /> {t('sessionPrint.print')}</Button>
        <Button size="large" onClick={history.goBack} ><Icon icon="check" fixedWidth /> {t('sessionPrint.done')}</Button>
      </div>}
      {session && session.interview_part === 1 && <SessionPart1 session={session} printView={true} />}
      {session && session.interview_part === 2 && <SessionPart2 session={session} />}
      {session && session.interview_part === 3 && <SessionPart3 session={session} />}
    </Spin>
  )

}