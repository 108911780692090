import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { range } from 'lodash'

import SessionType from '../components/SessionType'
import BoolValue from '../components/BoolValue'
import Satisfaction from '../components/Satisfaction'
import { callApi } from '../services/fetchService'

import { Descriptions } from 'antd'



export default ({ session }) => {

  const { t } = useTranslation()

  const [image, setImage] = useState(null)

  useEffect(() => {
    callApi({ endpoint: session.dialog_image }).then(data => setImage(URL.createObjectURL(data)))
  }, [session.dialog_image])

  return (
    <>
      <Descriptions title={t('sessionPart2.info')} column={1} size="small" bordered style={{ maxWidth: "600px" }} >
        <Descriptions.Item label={t('sessionPart2.info.patient')}>{session.patient_name}</Descriptions.Item>
        <Descriptions.Item label={t('sessionPart2.info.code')}>{session.patient_code}</Descriptions.Item>
        <Descriptions.Item label={t('sessionPart2.info.intervenant')}>{session.intervenant_name}</Descriptions.Item>
        <Descriptions.Item label={t('sessionPart2.info.start')}>{session.interview_start ? moment(session.interview_start).format('L LT') : '-'}</Descriptions.Item>
        <Descriptions.Item label={t('sessionPart2.info.duration')}>{session.interview_duration?.humanize() || '-'}</Descriptions.Item>
        <Descriptions.Item label={t('sessionPart2.info.alone')}><BoolValue value={session.without_intervenant} /></Descriptions.Item>
        <Descriptions.Item label={t('sessionPart2.info.part')}><SessionType part={session.interview_part} /></Descriptions.Item>
      </Descriptions>
      <br />
      <Descriptions title={t('sessionPart2.psychiatry')} column={1} size="small" bordered style={{ maxWidth: "800px" }} >
        <Descriptions.Item label={t('sessionPart2.psychiatry.contact')}>{session.psychiatry_first_contact}</Descriptions.Item>
        <Descriptions.Item label={t('sessionPart2.psychiatry.number')}>{session.psychiatry_nb_hospitalization}</Descriptions.Item>
        <Descriptions.Item label={t('sessionPart2.psychiatry.duration')}>
          {
            range(1, 7)
              .filter(i => session['psychiatry_hospitalization_' + i + '_duration'] !== "None")
              .map(i => <span key={i}>{i}<sup>e</sup> {t('sessionPart2.psychiatry.hospit')} :&nbsp;&nbsp;&nbsp;&nbsp;{session['psychiatry_hospitalization_' + i + '_duration']} {t('sessionPart2.psychiatry.weeks')}<br /></span>)
          }
        </Descriptions.Item>
      </Descriptions>
      <br />
      <Descriptions title={t('sessionPart2.satisfaction')} column={1} size="small" bordered style={{ maxWidth: "1200px" }}>
        <Descriptions.Item label={t('sessionPart2.satisfaction.mentalHealth')}><Satisfaction value={session.satisfaction_mental_health} /></Descriptions.Item>
        <Descriptions.Item label={t('sessionPart2.satisfaction.physicalHealth')}><Satisfaction value={session.satisfaction_physical_health} /></Descriptions.Item>
        <Descriptions.Item label={t('sessionPart2.satisfaction.employment')}><Satisfaction value={session.satisfaction_employment_situation} /></Descriptions.Item>
        <Descriptions.Item label={t('sessionPart2.satisfaction.housing')}><Satisfaction value={session.satisfaction_housing_situation} /></Descriptions.Item>
        <Descriptions.Item label={t('sessionPart2.satisfaction.activities')}><Satisfaction value={session.satisfaction_leisure_activities} /></Descriptions.Item>
        <Descriptions.Item label={t('sessionPart2.satisfaction.familyRelations')}><Satisfaction value={session.satisfaction_family_relations} /></Descriptions.Item>
        <Descriptions.Item label={t('sessionPart2.satisfaction.friendlyRelations')}><Satisfaction value={session.satisfaction_friendly_relations} /></Descriptions.Item>
        <Descriptions.Item label={t('sessionPart2.satisfaction.security')}><Satisfaction value={session.satisfaction_personal_security} /></Descriptions.Item>
        <Descriptions.Item label={t('sessionPart2.satisfaction.medicalTreatment')}><Satisfaction value={session.satisfaction_medical_treatment} /></Descriptions.Item>
        <Descriptions.Item label={t('sessionPart2.satisfaction.practicalAid')}><Satisfaction value={session.satisfaction_practical_aid} /></Descriptions.Item>
        <Descriptions.Item label={t('sessionPart2.satisfaction.meetings')}><Satisfaction value={session.satisfaction_mh_pro_meetings} /></Descriptions.Item>
      </Descriptions>
      <br />
      <div className="custom-descriptions-title">{t('sessionPart2.dialog+')}</div>
      <img src={image} style={{ maxWidth: 600 }} alt={t('sessionPart2.dialog+.alt')} />
      <p>{t('sessionPart2.dialog+.prem')}</p>
      <p>{t('sessionPart2.dialog+.prom')}</p>
      <p>{t('sessionPart2.dialog+.dot')}</p>
    </>
  )

}
