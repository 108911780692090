import React from 'react'

import { useTranslation } from 'react-i18next'

import { Tag } from 'antd'


export default (props) => {

  const small = props.small || false

  const { t } = useTranslation()

  return (
    <>
      {props.part && props.part === 1 && <Tag color="orange">{small ? t('sessionType.socioSmall') : t('sessionType.socio')}</Tag>}
      {props.part && props.part === 2 && <Tag color="green">{small ? t('sessionType.questionsSmall') : t('sessionType.questions')}</Tag>}
      {props.part && props.part === 3 && <Tag color="blue">{t('sessionType.honos')}</Tag>}
    </>
  )

}
