import React, { useState, useEffect, useContext } from 'react'

import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next'

import { callApi } from '../services/fetchService'
import Icon from '../components/Icon'
import SessionType from '../components/SessionType'

import { CurrentPatientContext } from '../contexts/CurrentPatientContext'

import moment from 'moment'

import { PageHeader, Spin, Table, Descriptions, Skeleton, message } from 'antd'
const { Column } = Table



export default () => {

  const { t } = useTranslation()

  let history = useHistory()

  let { id } = useParams()

  const { setCurrentPatient } = useContext(CurrentPatientContext)

  const [loading, setLoading] = useState(false)
  const [patient, setPatient] = useState()

  useEffect(() => {
    setLoading(true)
    callApi({
      endpoint: 'patients/' + id,
      method: 'GET'
    })
      .then((patient) => {
        patient.id = id
        setPatient(patient)
        setCurrentPatient(patient)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
        message.error(t('patient.unableToLoad'))
      })
  }, [id, setCurrentPatient, t])

  const onRow = (record, rowIndex) => {
    return {
      onClick: event => {
        history.push("/sessions/" + record.id)
      }
    }
  }



  return (
    <Spin size="large" spinning={loading}>
      <PageHeader title={<><Icon icon="user" fixedWidth /> {t('patient.title')} : {patient && (patient.patient.patient_name)}</>} />

      {!patient
        ? <Skeleton active />
        : <Descriptions title={t('patient.info')} column={1} size="small" bordered style={{ maxWidth: "600px" }} >
          <Descriptions.Item label={t('patient.name')}>{patient.patient.patient_name}</Descriptions.Item>
          <Descriptions.Item label={t('patient.code')}>{patient.patient.patient_code}</Descriptions.Item>
          <Descriptions.Item label={t('patient.nbSessions')}>{patient.sessions.length}</Descriptions.Item>
        </Descriptions>
      }

      <br />
      <div className="ant-descriptions-title">{t('patient.sessions')}</div>

      <Table dataSource={patient && patient.sessions} rowKey="id" loading={loading} onRow={onRow} rowClassName="sessions-row">
        <Column title={t('patient.columns.date')} key="interview_start" dataIndex="interview_start" render={date => moment(date).format('L LT')} />
        <Column title={t('patient.columns.intervenant')} key="intervenant_name" dataIndex="intervenant_name" />
        <Column title={t('patient.columns.part')} key="interview_part" dataIndex="interview_part" render={part => <SessionType part={part} />} />
      </Table>

    </Spin>
  )

}