import React, { useState, useContext, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { callApi } from '../services/fetchService'
import Icon from '../components/Icon'
import { AuthContext } from '../contexts/AuthContext';

import { PageHeader, Card, Row, Col, Form, Input, Button, Select, message } from 'antd'



export default () => {

  const { t } = useTranslation()

  const { user, service, refreshUser } = useContext(AuthContext)

  const [loading, setLoading] = useState(false)

  const [userForm] = Form.useForm()
  const [passwordForm] = Form.useForm()

  useEffect(() => {
    userForm.setFieldsValue({ ...user, service_name: service.service_name })
  }, [user, service, userForm])

  const editUser = values => {
    setLoading(true)
    delete values.confirm_password
    callApi({
      endpoint: 'auth/user',
      method: 'PUT',
      body: values
    }).then((data) => {
      if (values.password) {
        passwordForm.resetFields()
      }
      setLoading(false)
      refreshUser()
      message.success(t('profile.updated'))
    }).catch((error) => {
      setLoading(false)
      console.log(error)
      if (error.status === 401) {
        message.warning(t('profile.wrongPassword'))
      } else {
        message.error(t('profile.unableToUpdate'))
      }
    })
  }

  return (
    <>
      <PageHeader title={<><Icon icon="user" fixedWidth /> {t('profile.title')}</>} />
      <Row gutter={16}>
        <Col span={12}>
          <Card title={<><Icon icon="id-card" style={{ marginRight: 7 }} /> {t('profile.myData')}</>}>
            <Form form={userForm} layout="vertical" onFinish={editUser}>
              <Form.Item name="contact_name" label={t('profile.form.name')}>
                <Input prefix={<Icon icon="user" style={{ color: 'rgba(0,0,0,.25)' }} />} />
              </Form.Item>
              <Form.Item name="email" label={t('profile.form.email')}>
                <Input prefix={<Icon icon="envelope" style={{ color: 'rgba(0,0,0,.25)' }} />} disabled />
              </Form.Item>
              <Form.Item name="service_name" label={t('profile.form.service')}>
                <Input prefix={<Icon icon="building" style={{ color: 'rgba(0,0,0,.25)' }} />} disabled />
              </Form.Item>
              <Form.Item name="language" label={t('profile.form.language')}>
                <Select>
                  <Select.Option value="en">English</Select.Option>
                  <Select.Option value="fr">Français</Select.Option>
                </Select>
              </Form.Item>
              <Button type="primary" htmlType="submit" icon={<Icon icon="save" style={{ marginRight: 7 }} />} loading={loading}> {t('misc.save')}</Button>
            </Form>
          </Card>
        </Col>
        <Col span={12}>
          <Card title={<><Icon icon="lock" style={{ marginRight: 7 }} /> {t('profile.updatePassword')}</>}>
            <Form form={passwordForm} layout="vertical" onFinish={editUser}>
              <Form.Item name="password" label={t('profile.form.oldPassword')} rules={[{ required: true, message: t('profile.form.oldPasswordRequired') }]}>
                <Input type="password" prefix={<Icon icon="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} />
              </Form.Item>
              <Form.Item name="new_password" label={t('profile.form.newPassword')} rules={[
                { required: true, message: t('profile.form.newPasswordRequired') },
                { min: 8, message: t('profile.form.newPasswordLength') }
              ]}>
                <Input type="password" prefix={<Icon icon="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} />
              </Form.Item>
              <Form.Item name="confirm_password" label={t('profile.form.passwordConfirm')} dependencies={['new_password']} rules={[
                { required: true, message: t('profile.form.passwordConfirmRequired') },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('new_password') === value) return Promise.resolve()
                    return Promise.reject(new Error(t('profile.form.passwordConfirmSame')));
                  }
                })
              ]}>
                <Input type="password" prefix={<Icon icon="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} />
              </Form.Item>
              <Button type="primary" htmlType="submit" icon={<Icon icon="save" style={{ marginRight: 7 }} />} loading={loading}> {t('misc.save')}</Button>
            </Form>
          </Card>
        </Col>
      </Row>

    </>
  )

}