import i18n from "i18next"
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next"

import en from './locales/en.json'
import fr from './locales/fr.json'


// The translations
const resources = {
  en: {
    translation: en
  },
  fr: {
    translation: fr
  }
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    },
    react: {
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'small']
    }
  })

export default i18n

export const t = i18n.t