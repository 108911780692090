import React, { useState, useEffect, useContext } from 'react'

import { callApi } from '../services/fetchService'
import { LocaleContext } from './LocaleContext'



export const AuthContext = React.createContext()

export const AuthProvider = props => {

  const { setLocale } = useContext(LocaleContext)

  const login = (credentials) => {
    updateContext({ isLoading: true })
    return new Promise((resolve, reject) => {
      callApi({
        endpoint: 'auth/login',
        method: 'POST',
        body: credentials
      })
        .then((data) => {
          if (credentials.remember) {
            localStorage.setItem('token', data.token)
            localStorage.setItem('user', JSON.stringify(data.user))
            localStorage.setItem('service', JSON.stringify(data.service))
          } else {
            sessionStorage.setItem('token', data.token)
            sessionStorage.setItem('user', JSON.stringify(data.user))
            sessionStorage.setItem('service', JSON.stringify(data.service))
          }
          updateContext({ isAuthenticated: true, isLoading: false, ...data })
          resolve()
        })
        .catch((error) => {
          console.log(error)
          updateContext({ isLoading: false })
          reject(error)
        })
    })
  }

  const logout = () => {
    updateContext({ isAuthenticated: false, token: null, user: null, service: null })
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    localStorage.removeItem('service')
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('user')
    sessionStorage.removeItem('service')
  }

  const recover = () => {
    // If authenticated, no need to recover
    if (context.isAuthenticated) {
      return
    }
    // Check if token exists in localstorage
    let remember = true
    let token = localStorage.getItem('token')
    // Check if token exists in sessionStorage
    if (!token) {
      remember = false
      token = sessionStorage.getItem('token')
    }
    // If no token, stop recovering
    if (!token) {
      return
    }
    // Get user from DB
    updateContext({ isLoading: true })
    return new Promise((resolve, reject) => {
      callApi({
        endpoint: 'auth/user',
        method: 'GET'
      })
        .then((data) => {
          if (remember) {
            localStorage.setItem('user', JSON.stringify(data.user))
            localStorage.setItem('service', JSON.stringify(data.service))
          } else {
            sessionStorage.setItem('user', JSON.stringify(data.user))
            sessionStorage.setItem('service', JSON.stringify(data.service))
          }
          updateContext({ isAuthenticated: true, isLoading: false, token, ...data })
          resolve()
        })
        .catch((error) => {
          console.log(error)
          logout()
          updateContext({ isLoading: false })
          reject(error)
        })
    })
  }

  const refreshUser = () => {
    callApi({
      endpoint: 'auth/user',
      method: 'GET'
    })
      .then((data) => {
        updateContext({ isAuthenticated: true, ...data })
      })
      .catch(console.log)
  }

  const defaultContext = {
    isAuthenticated: false,
    isLoading: false,
    token: null,
    user: null,
    service: null,
    login,
    logout,
    recover,
    refreshUser
  }

  const [context, setContext] = useState(defaultContext)

  const updateContext = newContext => {
    newContext = Object.assign({}, context, newContext)
    setContext(newContext)
  }

  useEffect(() => {
    if (context?.user?.language) setLocale(context.user.language)
  }, [context, setLocale])

  return (
    <AuthContext.Provider value={context}>
      {props.children}
    </AuthContext.Provider>
  )

}