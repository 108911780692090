import React, { useState, useEffect } from 'react'

import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { callNcApi } from '../services/fetchNcService'
import { pairingCode } from '../services/utilService'
import Icon from '../components/Icon'
import TableButton from '../components/TableButton'
import DeviceStatus from '../components/DeviceStatus'

import { PageHeader, Table, Button, Modal, Form, Input, Alert, Space, message } from 'antd'
const { Column } = Table



export default () => {

  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()

  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [editForm] = Form.useForm()
  const [currentDevice, setCurrentDevice] = useState(null)

  const [isNewDeviceModalVisible, setIsNewDeviceModalVisible] = useState(false)
  const [newDeviceForm] = Form.useForm()

  const loadDevices = () => {
    setLoading(true)
    callNcApi({
      endpoint: 'devices',
      method: 'GET'
    })
      .then((data) => {
        setData(data)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
        message.error(t('devices.unableToLoad'))
      })
  }

  useEffect(loadDevices, [])

  useEffect(() => {
    if (isNewDeviceModalVisible) newDeviceForm.resetFields()
  }, [isNewDeviceModalVisible, newDeviceForm])

  const addNewDevice = (values) => {
    setLoading(true)
    callNcApi({ endpoint: 'devices', method: 'POST', body: values })
      .then((data) => {
        setLoading(false)
        setIsNewDeviceModalVisible(false)
        loadDevices()
        message.success(t('devices.added'))
      }).catch((error) => {
        setLoading(false)
        console.log(error)
        message.error(t('devices.enableToAdd'))
      })
  }

  const openEditDeviceModal = device => {
    setCurrentDevice(device)
    setIsEditModalVisible(true)
    editForm.setFieldsValue(device)
  }

  const renewDeviceCode = (device) => {
    setLoading(true)
    callNcApi({ endpoint: 'devices/' + device.id + '/renew', method: 'POST' })
      .then((data) => {
        setLoading(false)
        loadDevices()
        message.success(t('devices.codeRenewed'))
      }).catch((error) => {
        console.log(error)
        message.error(t('devices.unableToRenewCode'))
      })
  }

  const editDevice = (values) => {
    setLoading(true)
    callNcApi({ endpoint: 'devices/' + currentDevice.id, method: 'PUT', body: values })
      .then((data) => {
        setLoading(false)
        setIsEditModalVisible(false)
        loadDevices()
        message.success(t('devices.updated'))
      }).catch((error) => {
        console.log(error)
        message.error(t('devices.unableToUpdate'))
      })
  }

  const blockDevice = (device) => {
    setLoading(true)
    callNcApi({ endpoint: 'devices/' + device.id, method: 'PUT', body: { blocked: true } })
      .then((data) => {
        setLoading(false)
        loadDevices()
        message.success(t('devices.blocked'))
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
        message.error(t('devices.unableToBlock'))
      })
  }

  const unblockDevice = (device) => {
    setLoading(true)
    callNcApi({ endpoint: 'devices/' + device.id, method: 'PUT', body: { blocked: false } })
      .then((data) => {
        setLoading(false)
        loadDevices()
        message.success(t('devices.unblocked'))
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
        message.error(t('devices.unableToUnblock'))
      })
  }

  const deleteDevice = (device) => {
    Modal.confirm({
      title: t('devices.deleteModal.title'),
      content: <><p style={{ marginTop: 25 }}><strong>{t('devices.deleteModal.warning1')}</strong></p><p>{t('devices.deleteModal.warning2')}</p></>,
      okText: <><Icon icon="trash" /> {t('devices.deleteModal.delete')}</>,
      okButtonProps: { danger: true },
      onOk: () => {
        callNcApi({ endpoint: 'devices/' + device.id, method: 'DELETE' })
          .then((data) => {
            setLoading(false)
            loadDevices()
            message.success(t('devices.deleted'))
          }).catch((error) => {
            setLoading(false)
            message.error(t('devices.unableToDelete'))
          })
      }
    })
  }

  return (
    <>

      <PageHeader
        title={<><Icon icon="tablet-android" fixedWidth /> {t('devices.title')}</>}
        extra={<Button icon={<Icon icon="plus" style={{ marginRight: 10 }} />} onClick={() => setIsNewDeviceModalVisible(true)}> {t('devices.new')}</Button>}
      />

      <Table dataSource={data} rowKey="id" loading={loading}>
        <Column title={t('devices.columns.name')} dataIndex="name" />
        <Column title={t('devices.columns.description')} dataIndex="description" />
        <Column title={t('devices.columns.pairingCode')} render={d => pairingCode(d)} />
        <Column title={t('devices.columns.status')} render={device => <DeviceStatus device={device} />} />
        <Column title={t('devices.columns.actions')} width={100} render={device =>
          <Space>
            <TableButton icon="pencil" tooltip={t('devices.update')} onClick={() => openEditDeviceModal(device)} />
            {moment(device.expiration).isBefore(moment()) && <TableButton icon="undo" tooltip={t('devices.renewPairingCode')} onClick={() => renewDeviceCode(device)} />}
            {device.paired && device.blocked && <TableButton icon="check" tooltip={t('devices.unblock')} success onClick={() => unblockDevice(device)} />}
            {device.paired && !device.blocked && <TableButton icon="ban" tooltip={t('devices.block')} danger onClick={() => blockDevice(device)} />}
            <TableButton icon="trash" tooltip={t('devices.delete')} danger onClick={() => deleteDevice(device)} />
          </Space>
        } />
      </Table>

      <Modal
        title={<><Icon icon="plus" style={{ marginRight: 10 }} /> {t('devices.newModal.title')}</>}
        visible={isNewDeviceModalVisible}
        okText={t('devices.newModal.add')}
        okButtonProps={{ icon: <Icon icon="plus" style={{ marginRight: 7 }} />, loading }}
        onOk={newDeviceForm.submit}
        onCancel={() => setIsNewDeviceModalVisible(false)}
        destroyOnClose
      >
        <Alert showIcon message={t('devices.newModal.alert')} style={{ marginBottom: 30 }} />
        <Form form={newDeviceForm} onFinish={addNewDevice} layout="vertical">
          <Form.Item label={t('devices.newModal.name')} name="name" rules={[{ required: true, message: t('devices.newModal.nameRequired') }]}>
            <Input prefix={<Icon icon="tablet-android" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={t('devices.newModal.namePlaceholder')} disabled={loading} />
          </Form.Item>
          <Form.Item label={t('devices.newModal.description')} name="description">
            <Input prefix={<Icon icon="clipboard" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={t('devices.newModal.descriptionPlaceholder')} disabled={loading} />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={<><Icon icon="pencil" style={{ marginRight: 10 }} /> {t('devices.updateModal.title')} : {currentDevice?.name}</>}
        visible={isEditModalVisible}
        okText={t('devices.updateModal.update')}
        okButtonProps={{ icon: <Icon icon="save" style={{ marginRight: 7 }} />, loading }}
        onOk={editForm.submit}
        onCancel={() => setIsEditModalVisible(false)}
        destroyOnClose
      >
        <Form form={editForm} onFinish={editDevice} layout="vertical">
          <Form.Item label={t('devices.updateModal.name')} name="name" rules={[{ required: true, message: t('devices.updateModal.nameRequired') }]}>
            <Input prefix={<Icon icon="tablet-android" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={t('devices.updateModal.namePlaceholder')} disabled={loading} />
          </Form.Item>
          <Form.Item label={t('devices.updateModal.description')} name="description">
            <Input prefix={<Icon icon="clipboard" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={t('devices.updateModal.descriptionPlaceholder')} disabled={loading} />
          </Form.Item>
        </Form>
      </Modal>

    </>
  )

}