import React from 'react'

import { useTranslation } from 'react-i18next'

import Icon from '../components/Icon'



export default () => {

  const { t } = useTranslation()

  return (
    <div className="message" style={{ margin: "auto", paddingTop: "45px", textAlign: "center", maxWidth: "350px" }}>
      <Icon icon={["fal", "map-signs"]} size="8x" />
      <br />
      <span style={{ fontSize: "35px" }}>{t('404.title')}</span>
      <br /><br />
      {t('404.content')}
    </div>
  )

}