// API_URL fetched from environment
export const API_ROOT = process.env.REACT_APP_API_URL



// Fetches an API response.
// This makes every API response have the same shape, regardless of how nested it was.
export function callApi(callAPI) {
	// Check if array of calls
	if (callAPI instanceof Array) {
		return Promise.all(callAPI.map((item) => callApi(item)))
	}
	// Check endpoint
	const endpoint = callAPI.endpoint;
	if (typeof endpoint !== 'string') {
		throw new Error('Specify a string endpoint URL.')
	}
	// Make full url
	let fullUrl = new URL((endpoint.indexOf(API_ROOT) === -1) ? API_ROOT + endpoint : endpoint)
	// Create fetch options
	const options = {
		method: callAPI.method || 'GET',
		headers: callAPI.headers || {},
		body: JSON.stringify(callAPI.body)
	}
	// Add token
	if (localStorage.getItem('token')) {
		callAPI.token = localStorage.getItem('token')
	}
	// Add headers
	if (callAPI.body) {
		options.headers['Content-Type'] = callAPI.contentType || 'application/json'
	}
	if (callAPI.token) {
		options.headers['authorization'] = callAPI.token
	}
	// Add url parameters
	if (callAPI.params) {
		Object.keys(callAPI.params).forEach(key => fullUrl.searchParams.append(key, callAPI.params[key]))
	}
	// Fetch
	return fetch(fullUrl, options)
		.then(response => {
			const contentType = response.headers.get("content-type")
			if (contentType && contentType.indexOf("application/json") !== -1) {
				return response.json().then(data => {
					if (!response.ok) {
						data.status = response.status;
						return Promise.reject(data)
					}
					return Promise.resolve(data)
				})
			}
			if (!response.ok) {
				return Promise.reject({
					status: response.status
				})
			}
			if (contentType && contentType.indexOf("image/png") !== -1) {
				return response.blob().then(data => {
					return Promise.resolve(data)
				})
			}
			return Promise.resolve(response.text())
		});
}



export function getAuthHeaders() {
	return { 'authorization': localStorage.getItem('token') }
}