import React, { useState } from 'react'

import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Icon from '../components/Icon'
import { callApi } from '../services/fetchService'

import { Form, Input, Button, Alert, Result, message, Divider } from 'antd'

import './Login.css'



export default () => {

  const { t } = useTranslation()

  const token = new URLSearchParams(useLocation().search).get("token")

  const [loading, setLoading] = useState(false)
  const [isDone, setIsDone] = useState(false)

  const onFinish = values => {
    setLoading(true)
    delete values['confirm-password']
    callApi({ endpoint: 'auth/welcome', method: 'POST', body: { ...values, token } })
      .then(() => {
        setLoading(false)
        setIsDone(true)
      })
      .catch((error) => {
        setLoading(false)
        message.error(t('misc.unknownError'))
      })
  }

  return (
    < div className="register-form" >
      <div className="register-form-header">
        <Icon icon={['fal', 'chart-network']} size="8x" fixedWidth />
        <h1>Egonet</h1>
        <Divider />
      </div>
      {isDone &&
        <Result
          title={t('welcome.doneTitle')}
          subTitle={t('welcome.doneContent')}
          status="success"
          style={{ paddingTop: 40 }}
          icon={<Icon icon="check-circle" color="#52c41a" />}
          extra={<Link to="/login"><Button type="primary" icon={<Icon icon="lock" style={{ marginRight: 7 }} />}> {t('welcome.doneButton')}</Button></Link>}
        />
      }
      {!isDone && <>
        <Alert showIcon={true} message={t('welcome.alert.title')} description={t('welcome.alert.message')} style={{ marginBottom: 20 }} />
        <Form onFinish={onFinish} initialValues={{ remember: true }} layout="vertical">
          <Form.Item label={t('welcome.form.name')} name="contact_name" rules={[{ required: true, message: t('welcome.form.nameRequired') }]}>
            <Input prefix={<Icon icon="user" style={{ color: 'rgba(0,0,0,.25)' }} />} disabled={loading} />
          </Form.Item>
          <Form.Item label={t('welcome.form.password')} name="password" rules={[
            { required: true, message: t('welcome.form.passwordRequired') },
            { min: 8, message: t('welcome.form.passwordLength') }
          ]}>
            <Input prefix={<Icon icon="lock-alt" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" disabled={loading} />
          </Form.Item>
          <Form.Item label={t('welcome.form.passwordConfirm')} name="confirm-password" dependencies={['password']} rules={[
            { required: true, message: t('welcome.form.passwordConfirmRequired') },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) return Promise.resolve()
                return Promise.reject(new Error(t('welcome.form.passwordConfirmSame')));
              }
            })
          ]}>
            <Input prefix={<Icon icon="lock-alt" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" disabled={loading} />
          </Form.Item>
          <div className="login-form-button">
            <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>{t('welcome.form.submit')}</Button>
          </div>
        </Form>
      </>}
    </div >
  )

}