import React, { useState } from 'react'

import { isPlainObject, isNumber } from 'lodash'

import { callApi } from '../services/fetchService'



export const CurrentPatientContext = React.createContext()

export const CurrentPatientProvider = props => {

  const resetCurrentPatient = () => {
    updateContext({ patient: null })
  }

  const setCurrentPatient = (patient) => {
    if (isPlainObject(patient)) {
      updateContext({ patient })
    } else if (isNumber(patient)) {
      callApi({
        endpoint: 'patients/' + patient,
        method: 'GET'
      })
        .then((data) => {
          data.id = patient
          updateContext({ patient: data })
        })
        .catch((error) => {
          console.error(error)
          console.error("Unable to set current patient into context : Unknown error")
        })
    } else if (!patient) {
      updateContext({ patient: null })
    } else {
      console.error("Unable to set current patient into context : Unknown error")
    }
  }

  const defaultContext = {
    patient: null,
    setCurrentPatient,
    resetCurrentPatient
  }

  const [context, setContext] = useState(defaultContext)

  const updateContext = newContext => {
    newContext = Object.assign({}, context, newContext)
    setContext(newContext)
  }

  return (
    <CurrentPatientContext.Provider value={context}>
      {props.children}
    </CurrentPatientContext.Provider>
  )

}