import React, { useState, useEffect } from 'react'

import i18n from "i18next"

import { ConfigProvider } from 'antd'
import frBE from 'antd/es/locale/fr_BE'
import enGB from 'antd/es/locale/en_GB'

import contractFR from '../assets/Contrat de licence.pdf'
import contractEN from '../assets/License agreement.pdf'

import moment from 'moment'
import 'moment/locale/fr'
import 'moment/locale/en-gb'

moment.locale('en')



export const LocaleContext = React.createContext()

export const LocaleProvider = props => {

  const [locale, setLocale] = useState(i18n.language)

  useEffect(() => {
    if (locale) {
      i18n.changeLanguage(locale)
      moment.locale(locale)
    }
  }, [locale])

  const getAntLocale = () => {
    switch (locale) {
      case 'fr': return frBE
      case 'en':
      default: return enGB
    }
  }

  const contract = locale.includes('fr') ? contractFR : contractEN

  return (
    <LocaleContext.Provider value={{ locale, contract, setLocale }}>
      <ConfigProvider locale={getAntLocale()}>
        {props.children}
      </ConfigProvider>
    </LocaleContext.Provider>
  )

}