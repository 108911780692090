import React from 'react'

import Router from './Router'
import { AuthProvider } from '../contexts/AuthContext'
import { CurrentPatientProvider } from '../contexts/CurrentPatientContext'
import { LocaleProvider } from '../contexts/LocaleContext'

import 'antd/dist/antd.css'

// Init fontawesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from 'fontawesome-pro-regular-svg-icons'
import { faChartNetwork, faMapSigns, faLanguage } from 'fontawesome-pro-light-svg-icons'
import { faSquare, faCircle, faTriangle, faHexagon, faOctagon, faDiamond } from 'fontawesome-pro-solid-svg-icons'
library.add(far, faChartNetwork, faMapSigns, faSquare, faCircle, faTriangle, faHexagon, faOctagon, faDiamond, faLanguage)




export default () => {

  return (
    <LocaleProvider>
      <AuthProvider>
        <CurrentPatientProvider>
          <Router />
        </CurrentPatientProvider>
      </AuthProvider>
    </LocaleProvider>
  )

}