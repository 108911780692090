import React, { useContext, useEffect } from 'react'

import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import { AuthContext } from '../contexts/AuthContext'
import PrivateRoute from '../components/PrivateRoute'

import App from './App'
import Login from '../routes/Login'
import Register from '../routes/Register'
import SessionPrint from '../routes/SessionPrint'
import Welcome from '../routes/Welcome'
import PasswordReset from '../routes/ResetPassword'
import ForgottenPassword from '../routes/ForgottenPassword'



export default () => {

  const auth = useContext(AuthContext)
  const recover = auth.recover

  useEffect(() => { recover() }, [recover]);

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
        <Route path="/login">
          {auth.isAuthenticated ? <Redirect to={auth.user.super ? "/services" : "/sessions"} /> : <Login />}
        </Route>
        <Route path="/register">
          {auth.isAuthenticated ? <Redirect to="/sessions" /> : <Register />}
        </Route>
        <Route path="/welcome">
          <Welcome />
        </Route>
        <Route path="/password-reset">
          <PasswordReset />
        </Route>
        <Route path="/forgotten-password">
          <ForgottenPassword />
        </Route>
        <PrivateRoute path="/sessions/:id/print">
          <SessionPrint />
        </PrivateRoute>
        <PrivateRoute path="*">
          <App />
        </PrivateRoute>
      </Switch>
    </Router>
  )

}