import React from 'react'

import { useTranslation, Trans } from 'react-i18next'
import moment from 'moment'
import { range } from 'lodash'

import SessionType from '../components/SessionType'
import GenderIcon from '../components/GenderIcon'
import HonosTag from '../components/HonosTag'
import Info from '../components/Info'

import { Descriptions, Collapse, Tag } from 'antd'
const { Panel } = Collapse



export default (props) => {

  const { t } = useTranslation()

  const session = props.session

  const honosTotal = range(1, 13).reduce((acc, cur) => acc + (session[`honos_${cur}`] === 9 ? 0 : session[`honos_${cur}`] || 0), 0)

  return (
    <>
      <Descriptions title={t('sessionPart3.info')} column={1} size="small" bordered style={{ maxWidth: "600px" }} >
        <Descriptions.Item label={t('sessionPart3.info.patient')}>{session.patient_name}</Descriptions.Item>
        <Descriptions.Item label={t('sessionPart3.info.code')}>{session.patient_code}</Descriptions.Item>
        <Descriptions.Item label={t('sessionPart3.info.intervenant')}>{session.intervenant_name}</Descriptions.Item>
        <Descriptions.Item label={t('sessionPart3.info.start')}>{session.interview_start ? moment(session.interview_start).format('L LT') : '-'}</Descriptions.Item>
        <Descriptions.Item label={t('sessionPart3.info.part')}><SessionType part={session.interview_part} /></Descriptions.Item>
      </Descriptions>
      <br />
      <Descriptions title={t('sessionPart3.socio')} column={1} size="small" bordered style={{ maxWidth: "600px" }} >
        <Descriptions.Item label={t('sessionPart3.socio.date')}>{moment(session.admission_date).format('L')}</Descriptions.Item>
        <Descriptions.Item label={t('sessionPart3.socio.gender')}><GenderIcon gender={session.patient_gender} /></Descriptions.Item>
        <Descriptions.Item label={t('sessionPart3.socio.birthDate')}>{moment(session.patient_birth_date).format('L')}</Descriptions.Item>
        <Descriptions.Item label={t('sessionPart3.socio.nationality')}>{session.patient_nationality}</Descriptions.Item>
      </Descriptions>
      <br />
      <Descriptions title={t('sessionPart3.clin')} column={1} size="small" bordered style={{ maxWidth: "600px" }} >
        <Descriptions.Item label={t('sessionPart3.clin.diagnostic')}>
          {session.patient_diagnostic}
          <Info content={<Trans i18nKey="sessionPart3.clin.diagnosticInfo" />} />
        </Descriptions.Item>
      </Descriptions>
      <br />
      <div className="custom-descriptions-title">
        <Trans i18nKey="sessionPart3.honos" /> :
        <Tag style={{ marginLeft: 5 }} color="blue">{honosTotal}</Tag>
        <small style={{ color: 'grey' }}>{t('sessionPart3.honos.legend')}</small>
      </div>
      <Collapse expandIconPosition="right">
        {range(1, 13).map(n =>
          <Panel header={<span><HonosTag value={session[`honos_${n}`]} /> <Trans i18nKey={`sessionPart3.honos.${n}.title`} /></span>} key={n}>
            <Trans i18nKey={`sessionPart3.honos.${n}.description`} />
          </Panel>
        )}
      </Collapse>
    </>
  )

}
