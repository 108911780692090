import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'



export default function Icon(props) {

  const {...newProps} = props

  if (typeof props.icon === "string") {
    newProps.icon = ["far", props.icon]
  }

  return(
    <FontAwesomeIcon {...newProps} />
  )

}
