import React, { useState, useContext } from 'react'

import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { AuthContext } from '../contexts/AuthContext'
import { CurrentPatientContext } from '../contexts/CurrentPatientContext'
import Icon from '../components/Icon'
import LanguageSelector from '../components/LanguageSelector'

import { Form, Input, Button, Checkbox, message, Space, Divider } from 'antd'

import './Login.css'



export default () => {

  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const auth = useContext(AuthContext)
  const { resetCurrentPatient } = useContext(CurrentPatientContext)

  const onFinish = values => {
    setLoading(true)
    resetCurrentPatient()
    auth.login(values)
      .then(() => {
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        message.error(t('login.wrongCredentials'))
      })
  }

  return (
    <div className="login-form">
      <LanguageSelector />
      <div className="login-form-header">
        <Icon icon={['fal', 'chart-network']} size="8x" fixedWidth />
        <h1>Egonet</h1>
        <Divider />
      </div>
      <Form onFinish={onFinish} initialValues={{ remember: true }}>
        <Form.Item name="email" rules={[{ required: true, message: t('login.requiredEmail') }]}>
          <Input prefix={<Icon icon="user" />} placeholder={t('login.email')} disabled={loading} />
        </Form.Item>
        <Form.Item name="password" rules={[{ required: true, message: t('login.requiredPassword') }]}>
          <Input prefix={<Icon icon="lock-alt" />} type="password" placeholder={t('login.password')} disabled={loading} />
        </Form.Item>
        <Form.Item name="remember" valuePropName="checked">
          <Checkbox className="login-form-checkbox" disabled={loading}>{t('login.rememberMe')}</Checkbox>
        </Form.Item>
        <Space direction="vertical" className="login-form-button">
          <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>{t('login.signIn')}</Button>
          <Link to="/forgotten-password"><Button type="link" className="login-form-button">{t('login.forgottenPassword')}</Button></Link>
          <Link to="/register"><Button type="link" className="login-form-button">{t('login.register')}</Button></Link>
        </Space>
      </Form>
    </div>
  )

}