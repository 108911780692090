import React, { useState, useEffect } from 'react'

import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next'

import { callApi } from '../services/fetchService'
import Icon from '../components/Icon'

import { PageHeader, Table, message } from 'antd'
const { Column } = Table



export default () => {

  const { t } = useTranslation()

  let history = useHistory();

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 })
  const [paginationTotal, setPaginationTotal] = useState()

  useEffect(() => {
    setLoading(true)
    callApi({
      endpoint: 'patients',
      method: 'GET',
      params: { page: pagination.current, results: pagination.pageSize }
    })
      .then((data) => {
        setData(data.results)
        setLoading(false)
        setPaginationTotal(data.info.total)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
        message.error(t('patients.unableToLoad'))
      })
  }, [pagination, t])

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination)
  }

  const onRow = (record, rowIndex) => {
    return {
      onClick: event => {
        history.push("/patients/" + record.patient_id)
      }
    }
  }

  return (
    <>
      <PageHeader title={<><Icon icon="users" fixedWidth /> {t('patients.title')}</>} />
      <Table dataSource={data} rowKey="patient_id" loading={loading} pagination={{ ...pagination, total: paginationTotal }} onChange={handleTableChange} onRow={onRow} rowClassName="row-clickable">
        <Column title={t('patients.columns.name')} dataIndex="patient_name" />
        <Column title={t('patients.columns.code')} dataIndex="patient_code" />
        <Column title={t('patients.columns.nbSessions')} dataIndex="nb_sessions" />
      </Table>
    </>
  )

}