import React, { useContext } from 'react'

import { Switch, Link, Route, useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import moment from 'moment'

import Icon from '../components/Icon'
import { AuthContext } from '../contexts/AuthContext'
import { CurrentPatientContext } from '../contexts/CurrentPatientContext'

import './App.css'

import Profile from '../routes/Profile'
import Sessions from '../routes/Sessions'
import Session from '../routes/Session'
import Patients from '../routes/Patients'
import Patient from '../routes/Patient'
import NotFound from '../routes/404'
import Users from '../routes/Users'
import SessionType from '../components/SessionType'
import Devices from '../routes/Devices'
import Services from '../routes/Services'

import { Layout, Menu } from 'antd'
const { SubMenu } = Menu
const { Header, Content, Sider, Footer } = Layout



export default () => {

  const { t } = useTranslation()

  const auth = useContext(AuthContext)
  const { patient, resetCurrentPatient } = useContext(CurrentPatientContext)

  const location = useLocation()
  const history = useHistory()

  const handleUserMenuClick = e => {
    switch (e.key) {
      case 'profile': history.push('/profile'); break;
      case 'logout': auth.logout(); resetCurrentPatient(); break;
      default: console.warn('Unknown menu key: ' + e.key)
    }
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header style={{ padding: '0px' }}>
        <Menu theme="light" mode="horizontal" selectable={false} onClick={handleUserMenuClick} style={{ lineHeight: '64px', paddingRight: '20px', background: '#f8f8f8' }}>
          <Menu.Item disabled={true} className="logo" style={{ height: '64px' }} key="logo"><Icon icon={['fal', 'chart-network']} /> {t('header.title')} <small>{t('header.subtitle')}</small></Menu.Item>
          <SubMenu title={<span><Icon icon="user" />{auth.user.contact_name} ({auth.service.service_name})</span>} style={{ float: 'right' }} key="logout-submenu">
            <Menu.Item key="profile"><Icon icon="user-edit" />{t('userMenu.profile')}</Menu.Item>
            <Menu.Divider />
            <Menu.Item key="logout"><Icon icon="sign-out" />{t('userMenu.logout')}</Menu.Item>
          </SubMenu>
        </Menu>
      </Header>
      <Layout style={{ borderTop: '1px solid #e8e8e8' }}>
        <Sider width={200} style={{ background: '#f8f8f8', borderRight: '1px solid #e8e8e8' }}>
          <Menu mode="inline" selectedKeys={[location.pathname]} style={{ height: '100%', borderRight: 0, paddingTop: '30px', background: '#f8f8f8' }}>
            {auth.user.super
              ? <Menu.Item key="/services">
                <Link to="/services"><Icon icon="clinic-medical" fixedWidth /> {t('sidebar.services')}</Link>
              </Menu.Item>
              : <>
                <Menu.Item key="/sessions">
                  <Link to="/sessions"><Icon icon="clipboard-list" fixedWidth /> {t('sidebar.sessions')}</Link>
                </Menu.Item>
                <Menu.Item key="/patients">
                  <Link to="/patients"><Icon icon="users" fixedWidth /> {t('sidebar.patients')}</Link>
                </Menu.Item>
                {auth.user.is_admin && <Menu.Item key="/users">
                  <Link to="/users"><Icon icon="hospital-user" fixedWidth /> {t('sidebar.users')}</Link>
                </Menu.Item>}
                {auth.user.is_admin && <Menu.Item key="/devices">
                  <Link to="/devices"><Icon icon="tablet-android" fixedWidth /> {t('sidebar.tablets')}</Link>
                </Menu.Item>}
                {patient && <Menu.Divider style={{ marginTop: 20, marginBottom: 20 }} />}
                {patient && <Menu.Item key={"/patients/" + patient.id}>
                  <Link to={"/patients/" + patient.id}><Icon icon="user" /> {t('sidebar.patient')} : {patient.patient.patient_name}</Link>
                </Menu.Item>}
                {patient && patient.sessions.map(session =>
                  <Menu.Item key={"/sessions/" + session.id}>
                    <Link to={"/sessions/" + session.id}>{moment(session.interview_start).format('L')} <SessionType part={session.interview_part} small /></Link>
                  </Menu.Item>
                )}
              </>}
          </Menu>
        </Sider>
        <Layout>
          <Content style={{ background: '#fff', padding: 40, margin: 0, minHeight: 280 }}>
            <Switch location={location}>
              <Route exact path='/profile'><Profile /></Route>
              <Route exact path='/sessions'><Sessions /></Route>
              <Route path='/sessions/:id'><Session /></Route>
              <Route exact path='/patients'><Patients /></Route>
              <Route path='/patients/:id'><Patient /></Route>
              <Route exact path='/users'><Users /></Route>
              <Route exact path='/devices'><Devices /></Route>
              <Route exact path='/services'><Services /></Route>
              <Route path='*'><NotFound /></Route>
            </Switch>
          </Content>
          <Footer style={{ textAlign: 'center', background: '#f8f8f8' }}>
            &copy; {new Date().getFullYear()} {t('footer.developpedBy')} <a href="https://www.neoxis.be"><Icon icon="code" /> Neoxis</a>
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  )
}