import React from 'react'

import { useTranslation } from 'react-i18next'

import Icon from './Icon'

import { Tag } from 'antd'



export default ({ device }) => {

  const { t } = useTranslation()

  if (!device.paired) return <Tag color="blue"><Icon icon="hourglass" fixedWidth /> {t('deviceStatus.waiting')}</Tag>
  if (device.blocked) return <Tag color="volcano"><Icon icon="ban" fixedWidth /> {t('deviceStatus.blocked')}</Tag>
  return <Tag color="green"><Icon icon="check" fixedWidth /> {t('deviceStatus.used')}</Tag>

}
