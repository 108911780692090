import React from 'react'

import { Tag } from 'antd'


export default (props) => {

  switch (props.value) {
    case 0: return <Tag color="green">{props.value}</Tag>
    case 1: return <Tag color="green">{props.value}</Tag>
    case 2: return <Tag color="gold">{props.value}</Tag>
    case 3: return <Tag color="volcano">{props.value}</Tag>
    case 4: return <Tag color="red">{props.value}</Tag>
    case 9: return <Tag color="blue">{props.value}</Tag>
    default: return <>-</>
  }

}
