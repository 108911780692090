import React, { useState } from 'react'

import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { callApi } from '../services/fetchService'
import LanguageSelector from '../components/LanguageSelector'
import Icon from '../components/Icon'

import { Form, Input, Button, Divider, Result } from 'antd'



export default () => {

  const { t } = useTranslation()

  const [isDone, setIsDone] = useState(false)

  const token = new URLSearchParams(useLocation().search).get("token")

  const send = (values) => {
    callApi({ endpoint: '/auth/pw/accept', method: 'PUT', body: { token, password: values.password } })
      .then(() => { setIsDone(true) })
      .catch(console.error)
  }

  return (
    <div className="login-form">
      <LanguageSelector />
      <div className="login-form-header">
        <Icon icon={['fal', 'chart-network']} size="8x" fixedWidth />
        <h1>Egonet</h1>
        <Divider />
      </div>
      {isDone

        ? <Result
          title={t('resetPassword.doneTitle')}
          subTitle={t('resetPassword.doneContent')}
          status="success"
          style={{ paddingTop: 20 }}
          icon={<Icon icon="check-circle" />}
          extra={<Link to="/login"><Button type="primary" icon={<Icon icon="lock" />}>&nbsp; &nbsp;{t('resetPassword.doneButton')}</Button></Link>} />

        : <>
          <p style={{ textAlign: 'center' }}>{t('resetPassword.title')}</p>
          <Form onFinish={send} style={{ marginTop: 24 }}>
            <Form.Item name="password" rules={[
              { required: true, message: t('resetPassword.passwordRequired') },
              { min: 8, message: t('resetPassword.passwordLength') }
            ]}>
              <Input.Password prefix={<Icon icon="lock" />} placeholder={t('resetPassword.password')} />
            </Form.Item>
            <Form.Item name="confirm-password" dependencies={['password']} rules={[
              { required: true, message: t('resetPassword.passwordConfirmRequired') },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) return Promise.resolve()
                  return Promise.reject(new Error(t('resetPassword.passwordConfirmSame')));
                }
              })
            ]}>
              <Input.Password prefix={<Icon icon="lock" />} placeholder={t('resetPassword.passwordConfirm')} />
            </Form.Item>
            <Form.Item style={{ textAlign: 'center' }}>
              <Button type="primary" htmlType="submit" icon={<Icon icon="save" />}>&nbsp; &nbsp;{t('misc.save')}</Button>
            </Form.Item>
          </Form>
        </>}
    </div>
  )

}