import React, { useState, useEffect } from 'react'

import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { callApi } from '../services/fetchService'
import Icon from '../components/Icon'

import { PageHeader, Table, Switch, message } from 'antd'
const { Column } = Table




const EditSwitch = ({ checked, onChange }) => {
  return <Switch
    checkedChildren={<Icon icon="check" />}
    unCheckedChildren={<Icon icon="times" />}
    onChange={onChange}
    checked={checked}
  />
}

export default () => {

  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const loadServices = () => {
    setLoading(true)
    callApi({
      endpoint: 'super/services',
      method: 'GET'
    })
      .then((data) => {
        setData(data.services)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
        message.error(t('services.unableToLoad'))
      })
  }

  useEffect(loadServices, [])

  const editService = (id, values) => {
    setLoading(true)
    callApi({
      endpoint: 'super/service/' + id,
      method: 'PUT',
      body: values
    }).then((data) => {
      setLoading(false)
      loadServices()
      message.success(t('services.updated'))
    }).catch((error) => {
      setLoading(false)
      console.log(error)
      message.error(t('services.unableToUpdate'))
    })
  }

  return (
    <>
      <PageHeader title={<><Icon icon="clinic-medical" fixedWidth /> {t('services.title')}</>} />

      <Table dataSource={data} rowKey="service_id" loading={loading}>
        <Column title={t('services.columns.name')} dataIndex="service_name" />
        <Column title={t('services.columns.description')} dataIndex="service_description" />
        <Column title={t('services.columns.registrationDate')} dataIndex="created_on" render={d => d ? moment(d)?.format('L') : '-'} />
        <Column title={t('services.columns.dataUsed')} render={s => <EditSwitch checked={s.reporting_enabled} onChange={(checked) => editService(s.service_id, { reporting_enabled: checked })} />} />
        <Column title={t('services.columns.dataUploaded')} render={s => <EditSwitch checked={s.upload_enabled} onChange={(checked) => editService(s.service_id, { upload_enabled: checked })} />} />
        <Column title={t('services.columns.webappAccess')} render={s => <EditSwitch checked={s.connection_enabled} onChange={(checked) => editService(s.service_id, { connection_enabled: checked })} />} />
      </Table>

    </>
  )

}