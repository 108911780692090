import React, { useState, useEffect } from 'react'

import Highlighter from 'react-highlight-words';
import { useTranslation } from 'react-i18next'
import { useHistory } from "react-router-dom";

import { callApi } from '../services/fetchService'
import Icon from '../components/Icon'
import SessionType from '../components/SessionType'

import './Sessions.css'

import moment from 'moment'

import { PageHeader, Table, Input, Button, Row, Col, Radio, message } from 'antd'
const { Column } = Table



export default () => {

  const { t } = useTranslation()

  let history = useHistory();

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 })
  const [filters, setFilters] = useState({ interview_part: 1 })
  const [paginationTotal, setPaginationTotal] = useState()

  useEffect(() => {
    setLoading(true)
    callApi({
      endpoint: 'sessions',
      method: 'GET',
      params: { page: pagination.current, results: pagination.pageSize, sort: 'interview_start', direction: 'desc', ...filters }
    })
      .then((data) => {
        setData(data.results)
        setLoading(false)
        setPaginationTotal(data.info.total)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
        message.error(t('sessions.unableToLoad'))
      })
  }, [pagination, filters, t])

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination)
  }

  const onRow = (record, rowIndex) => {
    return {
      onClick: event => {
        history.push("/sessions/" + record.id)
      }
    }
  }

  const filterIcon = filtered => <Icon icon="search" fixedWidth size="lg" style={{ color: filtered ? '#1890ff' : undefined }} />

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={t('misc.search')}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 230, marginBottom: 8, display: 'block' }}
        />
        <Row>
          <Col span={12}><Button onClick={() => handleReset(clearFilters, dataIndex)} icon={<Icon icon="times" fixedWidth />} /></Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<Icon icon="search" fixedWidth />} />
          </Col>
        </Row>
      </div>
    ),
    filterIcon,
    render: text =>
      filters[dataIndex] ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[filters[dataIndex]]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
  })
  const interviewFilterProps = {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Radio.Group onChange={e => setSelectedKeys([e.target.value])} value={selectedKeys[0]} defaultValue={filters.interview_part}>
          <Radio style={radioStyle} value={1}><SessionType part={1} /></Radio>
          <Radio style={radioStyle} value={2}><SessionType part={2} /></Radio>
          <Radio style={radioStyle} value={3}><SessionType part={3} /></Radio>
        </Radio.Group>
        <Row style={{ marginTop: "10px" }}>
          <Col span={12}><Button onClick={() => handleReset(clearFilters, 'interview_part')} icon={<Icon icon="times" fixedWidth />} /></Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, 'interview_part')} icon={<Icon icon="search" fixedWidth />} />
          </Col>
        </Row>
      </div>
    ),
    filtered: !!filters.interview_part,
    filterIcon
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    if (selectedKeys[0]) {
      confirm()
      const newFilters = Object.assign({}, filters)
      newFilters[dataIndex] = selectedKeys[0]
      setFilters(newFilters)
    }
  }

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters()
    const newFilters = Object.assign({}, filters)
    delete newFilters[dataIndex]
    setFilters(newFilters)
  }

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  }

  return (
    <>
      <PageHeader title={<><Icon icon="clipboard-list" fixedWidth /> {t('sessions.title')}</>} />
      <Table dataSource={data} rowKey="id" loading={loading} pagination={{ ...pagination, total: paginationTotal }} onChange={handleTableChange} onRow={onRow} rowClassName="row-clickable">
        <Column title={t('sessions.columns.patient')} key="patient_name" dataIndex="patient_name" {...getColumnSearchProps("patient_name")} />
        <Column title={t('sessions.columns.code')} key="patient_code" dataIndex="patient_code" {...getColumnSearchProps("patient_code")} />
        <Column title={t('sessions.columns.intervenant')} key="intervenant_name" dataIndex="intervenant_name" {...getColumnSearchProps("intervenant_name")} />
        <Column title={t('sessions.columns.date')} key="interview_start" dataIndex="interview_start" render={date => date ? moment(date).format('L LT') : '-'} />
        <Column title={t('sessions.columns.part')} key="interview_part" dataIndex="interview_part" render={part => <SessionType part={part} />} {...interviewFilterProps} />
      </Table>
    </>
  )

}