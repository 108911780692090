import React, { useState, useContext } from 'react'

import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Icon from '../components/Icon'
import { callApi } from '../services/fetchService'
import LanguageSelector from '../components/LanguageSelector'
import { LocaleContext } from '../contexts/LocaleContext'

import { Form, Input, Button, Alert, message, Space, Result, Divider, Switch } from 'antd'

import './Register.css'



export default () => {

  const { t } = useTranslation()

  const { contract } = useContext(LocaleContext)

  const [loading, setLoading] = useState(false)
  const [isDone, setIsDone] = useState(false)

  const onFinish = values => {
    setLoading(true)
    callApi({ endpoint: 'auth/register', method: 'POST', body: values })
      .then(() => {
        setLoading(false)
        setIsDone(true)
      })
      .catch((error) => {
        setLoading(false)
        message.error(t('misc.unknownError'))
      })
  }

  if (isDone) {
    return (
      <div className="register-form">
        <LanguageSelector />
        <div className="register-form-header">
          <Icon icon={['fal', 'chart-network']} size="8x" fixedWidth />
          <h1>Egonet</h1>
          <Divider />
        </div>
        <Result
          title={t('register.doneTitle')}
          subTitle={t('register.doneContent')}
          status="success"
          style={{ paddingTop: 40 }}
          icon={<Icon icon="check-circle" color="#52c41a" />}
          extra={<Link to="/login"><Button type="primary" icon={<Icon icon="lock" fixedWidth />}> {t('register.doneButton')}</Button></Link>} />
      </div>
    )
  }

  return (
    <div className="register-form">
      <LanguageSelector />
      <div className="register-form-header">
        <Icon icon={['fal', 'chart-network']} size="8x" fixedWidth />
        <h1>Egonet</h1>
        <Divider />
      </div>
      <Alert showIcon={true} message={t('register.alert')} style={{ marginBottom: 20 }} />
      <Form onFinish={onFinish} initialValues={{ remember: true }} layout="vertical">
        <Form.Item label={t('register.form.name')} name="service_name" rules={[{ required: true, message: t('register.form.nameRequired') }]}>
          <Input prefix={<Icon icon="hospital-user" />} disabled={loading} />
        </Form.Item>
        <Form.Item label={t('register.form.description')} name="service_description" rules={[{ required: true, message: t('register.form.descriptionRequired') }]}>
          <Input prefix={<Icon icon="file-alt" />} disabled={loading} />
        </Form.Item>
        <Form.Item label={t('register.form.adminName')} name="contact_name" rules={[{ required: true, message: t('register.form.adminNameRequired') }]}>
          <Input prefix={<Icon icon="user" />} disabled={loading} />
        </Form.Item>
        <Form.Item label={t('register.form.adminEmail')} name="email" rules={[{ required: true, type: "email", message: t('register.form.adminEmailRequired') }]}>
          <Input prefix={<Icon icon="envelope" />} disabled={loading} />
        </Form.Item>
        <Form.Item label={t('register.form.password')} name="password" rules={[
          { required: true, message: t('register.form.passwordRequired') },
          { min: 8, message: t('register.form.passwordLength') }
        ]}>
          <Input prefix={<Icon icon="lock-alt" />} type="password" disabled={loading} />
        </Form.Item>
        <Form.Item label={t('register.form.passwordConfirm')} name="confirm-password" dependencies={['password']} rules={[
          { required: true, message: t('register.form.passwordConfirmRequired') },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) return Promise.resolve()
              return Promise.reject(new Error(t('register.form.passwordConfirmSame')));
            }
          })
        ]}>
          <Input prefix={<Icon icon="lock-alt" />} type="password" disabled={loading} />
        </Form.Item>
        <Form.Item
          name="confirm"
          label={<span>{t('register.acceptTermsOf')}&nbsp;<a href={contract} target="_blank" rel="noopener noreferrer">{t('register.licence')}</a>.</span>}
          valuePropName="checked"
          rules={[{ validator: async (_, value) => { if (!value) throw new Error(t('register.acceptLicenceRequired')) } }]}
        >
          <Switch checkedChildren={t('misc.yes')} unCheckedChildren={t('misc.no')} />
        </Form.Item>
        <Space direction="vertical" className="login-form-button">
          <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>{t('register.register')}</Button>
          <Link to="/login"><Button type="link" className="login-form-button">{t('misc.cancel')}</Button></Link>
        </Space>
      </Form>
    </div>
  )

}