import React, { useState, useEffect, useContext } from 'react'

import { useParams, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next'

import { CurrentPatientContext } from '../contexts/CurrentPatientContext'

import { callApi } from '../services/fetchService'
import Icon from '../components/Icon'
import SessionType from '../components/SessionType'
import SessionPart1 from '../components/SessionPart1';
import SessionPart2 from '../components/SessionPart2';
import SessionPart3 from '../components/SessionPart3';

import moment from 'moment'
import { partition } from 'lodash'

import { PageHeader, Spin, Button, message } from 'antd'



export default () => {

  const { t } = useTranslation()

  let { id } = useParams()

  const { setCurrentPatient } = useContext(CurrentPatientContext)

  const [loading, setLoading] = useState(false)
  const [session, setSession] = useState()

  useEffect(() => {
    setLoading(true)
    callApi({
      endpoint: 'sessions/' + id,
      method: 'GET'
    })
      .then((session) => {
        session.interview_duration = session.interview_start && session.interview_end && moment.duration(moment(session.interview_start).diff(moment(session.interview_end)))
        if (session.alters) {
          session.alters.forEach((alter => {
            alter.role = alter.role || alter.link_type || alter.function
          }))
          session.alters = partition(session.alters, { type: 'medical' })
        }
        setSession(session)
        setCurrentPatient(session.patient_id)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
        message.error(t('session.unableToLoad'))
      })
  }, [id, setCurrentPatient, t])

  return (
    <Spin size="large" spinning={loading}>
      <PageHeader
        title={<><Icon icon="clipboard-check" fixedWidth /> {t('session.title')} : {session && (session.patient_name)}</>}
        subTitle={<><SessionType part={session && session.interview_part} /> {session && moment(session.interview_start).format('L LT')}</>}
        extra={[<Link to={"/sessions/" + id + "/print"} key="link"><Button><Icon icon="print" style={{ marginRight: "10px" }} />{t('session.print')}</Button></Link>]} />
      {session && session.interview_part === 1 && <SessionPart1 session={session} />}
      {session && session.interview_part === 2 && <SessionPart2 session={session} />}
      {session && session.interview_part === 3 && <SessionPart3 session={session} />}
    </Spin>
  )

}