import React, { useState, useEffect } from 'react'

import Icon from '../components/Icon'
import SessionType from '../components/SessionType'
import GenderIcon from '../components/GenderIcon'
import BoolValue from '../components/BoolValue'
import Satisfaction from '../components/Satisfaction'
import Info from './Info'

import './SessionPart1.css'

import moment from 'moment'
import { reduce, debounce, round, union, groupBy, toPairs, map, countBy, flatten } from 'lodash'
import { useSessionStorage } from 'react-use'
import { useTranslation, Trans } from 'react-i18next'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import { Pie } from '@ant-design/charts';

import { Descriptions, Table, Tag, Tabs, Select, Row, Col, Form, Divider, Typography, Switch, Radio, List } from 'antd'
import { t } from 'i18next'
const { Column } = Table
const { Text } = Typography
const { TabPane } = Tabs
const { Option } = Select;



const alterDegreeTitle = <>
  <Trans i18nKey="sessionPart1.tabs.metrics.columns.degree" />
  <Info content={<Trans i18nKey="sessionPart1.tabs.metrics.columns.degree.info" />} />
</>

const alterBetweenessTitle = <>
  <Trans i18nKey="sessionPart1.tabs.metrics.columns.betweeness" />
  <Info content={<Trans i18nKey="sessionPart1.tabs.metrics.columns.betweeness.info" />} />
</>



export default ({ session, printView = false }) => {

  const { t } = useTranslation()

  const [showMetrics, setShowMetrics] = useState(false)
  const [showFilters, setShowFilters] = useState(false)
  const [filter, setFilter] = useState("all")
  const [noteContent, setNoteContent] = useState()
  const [tab, setTab] = useState("info")

  const onNoteContentChange = debounce(noteContent => setNoteContent(noteContent), 1000)

  return (
    printView ?
      <div className="print-zone">
        <SessionPart1Infos session={session} />
        <br />
        <SessionPart1Helpers showMetrics={false} session={session} />
        <br />
        <div className="custom-descriptions-title">{t('sessionPart1.sociogram')}</div>
        <SessionPart1Sociogram session={session} printView />
        <SessionPart1Metrics session={session} />
      </div>
      :
      <Tabs activeKey={tab} onChange={key => setTab(key)}>
        <TabPane tab={<span><Icon icon="info-circle" fixedWidth /> {t('sessionPart1.tabs.info')}</span>} key="info">
          <SessionPart1Infos session={session} />
        </TabPane>
        <TabPane tab={<span><Icon icon="users" fixedWidth /> {t('sessionPart1.tabs.alters')}</span>} key="help">
          <div style={{ float: "right", textAlign: 'right' }}>
            {t('sessionPart1.tabs.alters.showMetrics')} : <Switch checked={showMetrics} onChange={checked => setShowMetrics(checked)} />
            <br style={{ marginBottom: 5 }} />
            {t('sessionPart1.tabs.alters.showFilters')} : <Switch checked={showFilters} onChange={checked => setShowFilters(checked)} />
          </div>
          {showFilters && <><div className="custom-descriptions-title">Filtres</div>
            <Radio.Group value={filter} onChange={e => setFilter(e.target.value)}>
              <Radio value="all">{t('sessionPart1.tabs.alters.filters.all')}</Radio><br />
              <Radio value="psycho">{t('sessionPart1.tabs.alters.filters.psycho')}</Radio><br />
              <Radio value="regular">{t('sessionPart1.tabs.alters.filters.regular')}</Radio><br />
              <Radio value="crisis">{t('sessionPart1.tabs.alters.filters.crisis')}</Radio><br />
              <Radio value="largeHelp">{t('sessionPart1.tabs.alters.filters.largeHelp')}</Radio><br />
              <Radio value="important">{t('sessionPart1.tabs.alters.filters.important')}</Radio>
            </Radio.Group></>}
          <SessionPart1Helpers showMetrics={showMetrics} filter={filter} session={session} />
        </TabPane>
        <TabPane tab={<span><Icon icon="chart-network" fixedWidth /> {t('sessionPart1.tabs.sociogram')}</span>} key="socio">
          <SessionPart1Sociogram session={session} />
        </TabPane>
        <TabPane tab={<span><Icon icon="clipboard-list" fixedWidth /> {t('sessionPart1.tabs.reading')}</span>} key="lecture">
          <SessionPart1Questions session={session} setFilter={setFilter} setShowFilters={setShowFilters} setTab={setTab} />
        </TabPane>
        <TabPane tab={<span><Icon icon="function" fixedWidth /> {t('sessionPart1.tabs.metrics')}</span>} key="metrics">
          <SessionPart1Metrics session={session} />
        </TabPane>
        <TabPane tab={<span><Icon icon="pencil-alt" fixedWidth /> {t('sessionPart1.tabs.notes')}</span>} key="notes">
          <Editor editorClassName="note-editor" onContentStateChange={onNoteContentChange} contentState={noteContent} />
        </TabPane>
      </Tabs>
  )

}



/**
 * Métriques
 */
export const SessionPart1Infos = (props) => {

  const { session } = props

  return (
    <>
      <Descriptions title={t('sessionPart1.info')} column={1} size="small" bordered style={{ maxWidth: "600px" }} >
        <Descriptions.Item label={t('sessionPart1.info.patient')}>{session.patient_name}</Descriptions.Item>
        <Descriptions.Item label={t('sessionPart1.info.code')}>{session.patient_code}</Descriptions.Item>
        <Descriptions.Item label={t('sessionPart1.info.intervenant')}>{session.intervenant_name}</Descriptions.Item>
        <Descriptions.Item label={t('sessionPart1.info.start')}>{session.interview_start ? moment(session.interview_start).format('L LT') : '-'}</Descriptions.Item>
        <Descriptions.Item label={t('sessionPart1.info.duration')}>{session.interview_duration?.humanize() || '-'}</Descriptions.Item>
        <Descriptions.Item label={t('sessionPart1.info.part')}><SessionType part={session.interview_part} /></Descriptions.Item>
      </Descriptions>
      <br />
      <div className="custom-descriptions-title">{t('sessionPart1.satisfaction.title')}</div>
      <Table bordered pagination={false} rowKey="question" dataSource={[
        {
          question: t('sessionPart1.satisfaction.question1'),
          before: session.entourage_satisfaction_1,
          after: session.entourage_satisfaction_2
        },
        {
          question: t('sessionPart1.satisfaction.question2'),
          before: session.entourage_size_1,
          after: session.entourage_size_2
        },
        {
          question: t('sessionPart1.satisfaction.question3'),
          before: session.entourage_communication_1,
          after: session.entourage_communication_2
        }
      ]}>
        <Column title={t('sessionPart1.satisfaction.question')} dataIndex="question" key="question" />
        <Column title={t('sessionPart1.satisfaction.before')} dataIndex="before" key="before" render={value => <Satisfaction value={value} />} />
        <Column title={t('sessionPart1.satisfaction.after')} dataIndex="after" key="after" render={value => <Satisfaction value={value} />} />
      </Table>
    </>
  )

}



/**
 * Personnes soutenantes (helpers)
 */
export const SessionPart1Helpers = (props) => {

  const { showMetrics, filter, session } = props

  let pro = session.alters[0]
  let nonpro = session.alters[1]
  switch (filter) {
    case "regular":
      pro = pro.filter(a => a.met_last_week)
      nonpro = nonpro.filter(a => a.met_last_week)
      break;
    case "crisis":
      pro = pro.filter(a => a.count_when_crisis)
      nonpro = nonpro.filter(a => a.count_when_crisis)
      break;
    case "largeHelp":
      pro = pro.filter(a => a.help.length === 4)
      nonpro = nonpro.filter(a => a.help.length === 4)
      break;
    case "important":
      pro = pro.filter(a => a.proximity_ring <= 2)
      nonpro = nonpro.filter(a => a.proximity_ring <= 2)
      break;
    case "psycho":
      pro = pro.filter(a => a.alter_category === "Santé mentale")
      nonpro = nonpro.filter(a => a.alter_category === "Santé mentale")
      break;
    default:
      break;
  }

  return (
    <>
      <div className="custom-descriptions-title" style={{ marginTop: 20 }}>{t('sessionPart1.alters.nonProfessionals')}</div>
      <Table bordered pagination={false} dataSource={nonpro} size="small" rowKey="uid">
        <Column title={t('sessionPart1.alters.columns.name')} dataIndex="name" key="name" />
        <Column title={t('sessionPart1.alters.columns.gender')} dataIndex="gender" key="gender" render={value => <GenderIcon gender={value} />} />
        <Column title={t('sessionPart1.alters.columns.group')} dataIndex="group" key="group" render={value => <BoolValue value={value} />} />
        <Column title={t('sessionPart1.alters.columns.groupSize')} dataIndex="group_size" key="group_size" />
        <Column title={t('sessionPart1.alters.columns.role2')} dataIndex="role" key="role" render={(value, row) => <> {row.function || row.service || row.last_met_date_pro ? <Info type="warning" content={<>
          <strong>{t('sessionPart1.alters.columns.role2.warning')} :</strong><br />
          <strong>{t('sessionPart1.alters.columns.role2.warning.function')} :</strong> {row.function}<br />
          <strong>{t('sessionPart1.alters.columns.role2.warning.service')} :</strong> {row.service}<br />
          <strong>{t('sessionPart1.alters.columns.role2.warning.lastMet')} :</strong> {row.last_met_date_pro}<br />
        </>} /> : null} {value}</>} />
        <Column title={<>
          {t('sessionPart1.alters.columns.domains')}
          <Info content={<>
            <Tag icon={<Icon icon="landmark" fixedWidth />} color="blue" key={"1"} style={{ margin: "2px 5px 2px 0" }} />{t('sessionPart1.domainLabel.finances')}<br />
            <Tag icon={<Icon icon="home" fixedWidth />} color="purple" key={"2"} style={{ margin: "2px 5px 2px 0" }} />{t('sessionPart1.domainLabel.livingPlace')}<br />
            <Tag icon={<Icon icon="users" fixedWidth />} color="green" key={"3"} style={{ margin: "2px 5px 2px 0" }} />{t('sessionPart1.domainLabel.activities')}<br />
            <Tag icon={<Icon icon="heartbeat" fixedWidth />} color="orange" key={"4"} style={{ margin: "2px 5px 2px 0" }} />{t('sessionPart1.domainLabel.health')}<br />
          </>} />
        </>} dataIndex="help" key="help" render={(domaines, row) => domaines.sort().map(domaine => {
          switch (domaine) {
            case 'finances': return <Tag icon={<Icon icon="landmark" fixedWidth />} color="blue" key={"1"} />
            case 'living-place': return <Tag icon={<Icon icon="home" fixedWidth />} color="purple" key={"2"} />
            case 'activities': return <Tag icon={<Icon icon="users" fixedWidth />} color="green" key={"3"} />
            case 'health': return <Tag icon={<Icon icon="heartbeat" fixedWidth />} color="orange" key={"4"} />
            default: return null
          }
        })
        } />
        <Column title={<>{t('sessionPart1.alters.columns.lastWeek')}<Info content={t('sessionPart1.alters.columns.lastWeek.info')} /></>} dataIndex="met_last_week" key="met_last_week" render={value => <BoolValue value={value} />} />
        <Column title={<>{t('sessionPart1.alters.columns.crisis')}<Info content={t('sessionPart1.alters.columns.crisis.info')} /></>} dataIndex="count_when_crisis" key="count_when_crisis" render={value => <BoolValue value={value} />} />
        <Column title={t('sessionPart1.alters.columns.proximity')} dataIndex="proximity_ring" key="proximity_ring" />
        {showMetrics && <>
          <Column title={alterDegreeTitle} dataIndex="alter_degree" key="alter_degree" render={value => round(value, 3)} />
          <Column title={alterBetweenessTitle} dataIndex="alter_betweenness" key="alter_betweenness" render={value => round(value, 3)} />
        </>
        }
      </Table>
      <br />
      <div className="custom-descriptions-title">{t('sessionPart1.alters.professionals')}</div>
      <Table bordered pagination={false} dataSource={pro} size="small" rowKey="uid">
        <Column title={t('sessionPart1.alters.columns.name')} dataIndex="name" key="name" />
        <Column title={t('sessionPart1.alters.columns.gender')} dataIndex="gender" key="gender" render={value => <GenderIcon gender={value} />} />
        <Column title={t('sessionPart1.alters.columns.group')} dataIndex="group" key="group" render={value => <BoolValue value={value} />} />
        <Column title={t('sessionPart1.alters.columns.groupSize')} dataIndex="group_size" key="group_size" />
        <Column title={t('sessionPart1.alters.columns.role')} dataIndex="role" key="role" render={(value, row) => <> {row.link_type ? <Info type="warning" content={<>
          <strong>L'utilisateur à rempli des champs d'acteur familial :</strong><br />
          <strong>Lien :</strong> {row.link_type}<br />
        </>} /> : null} {value}</>} />
        <Column title={t('sessionPart1.alters.columns.service')} dataIndex="service" key="service" />
        <Column title={<>
          {t('sessionPart1.alters.columns.domains')}
          <Info content={<>
            <Tag icon={<Icon icon="landmark" fixedWidth />} color="blue" key={"1"} style={{ margin: "2px 5px 2px 0" }} />{t('sessionPart1.domainLabel.finances')}<br />
            <Tag icon={<Icon icon="home" fixedWidth />} color="purple" key={"2"} style={{ margin: "2px 5px 2px 0" }} />{t('sessionPart1.domainLabel.livingPlace')}<br />
            <Tag icon={<Icon icon="users" fixedWidth />} color="green" key={"3"} style={{ margin: "2px 5px 2px 0" }} />{t('sessionPart1.domainLabel.activities')}<br />
            <Tag icon={<Icon icon="heartbeat" fixedWidth />} color="orange" key={"4"} style={{ margin: "2px 5px 2px 0" }} />{t('sessionPart1.domainLabel.health')}<br />
          </>} />
        </>} dataIndex="help" key="help" render={(domaines, row) => domaines.sort().map(domaine => {
          switch (domaine) {
            case 'finances': return <Tag icon={<Icon icon="landmark" fixedWidth />} color="blue" key={"1"} />
            case 'living-place': return <Tag icon={<Icon icon="home" fixedWidth />} color="purple" key={"2"} />
            case 'activities': return <Tag icon={<Icon icon="users" fixedWidth />} color="green" key={"3"} />
            case 'health': return <Tag icon={<Icon icon="heartbeat" fixedWidth />} color="orange" key={"4"} />
            default: return null
          }
        })
        } />
        <Column title={<>{t('sessionPart1.alters.columns.lastWeek')}<Info content={t('sessionPart1.alters.columns.lastWeek.info')} /></>} dataIndex="met_last_week" key="met_last_week" render={value => <BoolValue value={value} />} />
        <Column title={<>{t('sessionPart1.alters.columns.crisis')}<Info content={t('sessionPart1.alters.columns.crisis.info')} /></>} dataIndex="count_when_crisis" key="count_when_crisis" render={value => <BoolValue value={value} />} />
        <Column title={t('sessionPart1.alters.columns.proximity')} dataIndex="proximity_ring" key="proximity_ring" />
        {showMetrics && <>
          <Column title={alterDegreeTitle} dataIndex="alter_degree" key="alter_degree" render={value => round(value, 3)} />
          <Column title={alterBetweenessTitle} dataIndex="alter_betweenness" key="alter_betweenness" render={value => round(value, 3)} />
        </>
        }
      </Table>
    </>
  )
}



/**
 * Sociogramme
 */
export const SessionPart1Sociogram = ({ session, printView = false }) => {

  const { t } = useTranslation()

  const getImageUrl = (values) => session.image + '?' + reduce(values, (result, value, key) => result + key + '=' + value + '&', '')

  const formInitialValues = {
    style: 'default',
    shape: 'default',
    'color-hue': 'default',
    levels: 'default',
    labels: 'name'
  }

  const [formValues, setFormValues] = useSessionStorage(`sociogram_${session.id}`, formInitialValues)

  const [imageData, setImageData] = useState()

  const [form] = Form.useForm()

  useEffect(() => {
    console.log("effect")
    form.setFieldsValue(formValues)
    fetch(getImageUrl(formValues), { headers: { authorization: localStorage.getItem('token') } })
      .then(response => response.blob())
      .then(blob => {
        var reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = function () {
          setImageData(reader.result)
        }
      })
  }, [formValues]) // eslint-disable-line react-hooks/exhaustive-deps

  if (printView) return <img src={imageData} alt="sociogram" />

  return (
    <Row>
      <Col flex="100px">
        <Form form={form} initialValues={formValues} onValuesChange={(changed, all) => setFormValues(all)}>
          <Form.Item name="style" label={t('sessionPart1.sociogram.style')}>
            <Select style={{ width: 200 }}>
              <Option value="kamada-kawai">{t('sessionPart1.sociogram.style.kamadakawai')}</Option>
              <Option value="fruchterman-reingold">{t('sessionPart1.sociogram.style.fruchterman')}</Option>
              <Option value="circular">{t('sessionPart1.sociogram.style.circular')}</Option>
              <Option value="default">{t('sessionPart1.sociogram.style.default')}</Option>
            </Select>
          </Form.Item>
          <Form.Item name="shape" label={t('sessionPart1.sociogram.shape')}>
            <Select style={{ width: 200 }}>
              <Option value="default">/</Option>
              <Option value="gender">{t('sessionPart1.sociogram.shape.gender')}</Option>
              <Option value="type">{t('sessionPart1.sociogram.shape.type')}</Option>
              <Option value="group">{t('sessionPart1.sociogram.shape.group')}</Option>
              <Option value="count_when_crisis">{t('sessionPart1.sociogram.shape.crisis')}</Option>
              <Option value="met_last_week">{t('sessionPart1.sociogram.shape.lastWeek')}</Option>
              <Option value="help-finances">{t('sessionPart1.sociogram.shape.finances')}</Option>
              <Option value="help-activities">{t('sessionPart1.sociogram.shape.activities')}</Option>
              <Option value="help-living_place">{t('sessionPart1.sociogram.shape.livingPlace')}</Option>
              <Option value="help-health">{t('sessionPart1.sociogram.shape.health')}</Option>
            </Select>
          </Form.Item>
          <Form.Item name="color-hue" label={t('sessionPart1.sociogram.color')}>
            <Select style={{ width: 200 }}>
              <Option value="default">/</Option>
              <Option value="gender">{t('sessionPart1.sociogram.shape.gender')}</Option>
              <Option value="type">{t('sessionPart1.sociogram.shape.type')}</Option>
              <Option value="group">{t('sessionPart1.sociogram.shape.group')}</Option>
              <Option value="count_when_crisis">{t('sessionPart1.sociogram.shape.crisis')}</Option>
              <Option value="met_last_week">{t('sessionPart1.sociogram.shape.lastWeek')}</Option>
              <Option value="help-finances">{t('sessionPart1.sociogram.shape.finances')}</Option>
              <Option value="help-activities">{t('sessionPart1.sociogram.shape.activities')}</Option>
              <Option value="help-living_place">{t('sessionPart1.sociogram.shape.livingPlace')}</Option>
              <Option value="help-health">{t('sessionPart1.sociogram.shape.health')}</Option>
            </Select>
          </Form.Item>
          <Form.Item name="levels" label={t('sessionPart1.sociogram.levels')}>
            <Select style={{ width: 200 }}>
              <Option value="default">/</Option>
              <Option value="ring">{t('sessionPart1.sociogram.levels.ring')}</Option>
              <Option value="help">{t('sessionPart1.sociogram.levels.help')}</Option>
            </Select>
          </Form.Item>
          <Form.Item name="labels" label={t('sessionPart1.sociogram.labels')}>
            <Select style={{ width: 200 }}>
              <Option value="name">{t('sessionPart1.sociogram.labels.name')}</Option>
              <Option value="link-type">{t('sessionPart1.sociogram.labels.link')}</Option>
              <Option value="off">{t('sessionPart1.sociogram.labels.off')}</Option>
            </Select>
          </Form.Item>
          <Form.Item name="harmful_relationship" label={<>{t('sessionPart1.sociogram.showHarmful')} <Info content={t('sessionPart1.sociogram.showHarmful.info')} /></>} valuePropName="checked">
            <Switch />
          </Form.Item>
        </Form>
      </Col>
      <Col flex="auto">
        <Divider type="vertical" style={{ height: "100%", margin: "0px 50px" }} />
        <img src={imageData} alt="sociogram" />
      </Col>
    </Row>
  )

}



/**
 * Métriques
 */
export const SessionPart1Metrics = (props) => {

  const { t } = useTranslation()

  const { session } = props
  const metrics = session.metrics
  const metricsData = [
    { key: "alters", value: metrics.alters },
    { key: "professionalProportion", value: metrics.professional_proportion },
    { key: "density", value: (round(metrics.density, 3) * 100) + "%" },
    { key: "nbComponents", value: metrics.nb_components },
    { key: "isolated", value: metrics.isolated },
    { key: "triads", value: metrics.triads }
  ]

  return (
    <>
      <div className="custom-descriptions-title">{t(`sessionPart1.tabs.metrics.title`)}</div>
      <Table bordered showHeader={false} pagination={false} dataSource={metricsData} size="small" rowKey="key" style={{ maxWidth: "750px" }}>
        <Column render={item => <>
          <Text strong={true}>{t(`sessionPart1.tabs.metrics.${item.key}.title`)}</Text>
          <br />
          <Text type="secondary">{t(`sessionPart1.tabs.metrics.${item.key}.description`)}</Text>
        </>} />
        <Column dataIndex="value" />
      </Table>
      <br />
      <div className="custom-descriptions-title">{t(`sessionPart1.tabs.metrics.degree.nonProfessionals`)}</div>
      <Table bordered pagination={false} dataSource={session.alters[0]} size="small" rowKey="uid" style={{ maxWidth: "750px" }}>
        <Column title="Nom" dataIndex="name" key="name" />
        <Column title={alterDegreeTitle} dataIndex="alter_degree" key="alter_degree" render={value => round(value, 3)} />
        <Column title={alterBetweenessTitle} dataIndex="alter_betweenness" key="alter_betweenness" render={value => round(value, 3)} />
      </Table>
      <br />
      <div className="custom-descriptions-title">{t(`sessionPart1.tabs.metrics.degree.professionals`)}</div>
      <Table bordered pagination={false} dataSource={session.alters[1]} size="small" rowKey="uid" style={{ maxWidth: "750px" }}>
        <Column title="Nom" dataIndex="name" key="name" />
        <Column title={alterDegreeTitle} dataIndex="alter_degree" key="alter_degree" render={value => round(value, 3)} />
        <Column title={alterBetweenessTitle} dataIndex="alter_betweenness" key="alter_betweenness" render={value => round(value, 3)} />
      </Table>
    </>
  )

}



/**
 * Questions
 */
export const SessionPart1Questions = (props) => {

  const { t } = useTranslation()

  const { session, setFilter, setShowFilters, setTab } = props
  const metrics = session.metrics

  const groupedDomains = countBy(flatten(map(union(session.alters[0], session.alters[1]), 'help')))
  const emptyDomains = []
  if (!groupedDomains.activities) emptyDomains.push(t('sessionPart1.domainLabel.activities'))
  if (!groupedDomains.finances) emptyDomains.push(t('sessionPart1.domainLabel.finances'))
  if (!groupedDomains.health) emptyDomains.push(t('sessionPart1.domainLabel.health'))
  if (!groupedDomains["living-place"]) emptyDomains.push(t('sessionPart1.domainLabel.livingPlace'))

  const domains = toPairs({
    activities: groupedDomains.activities || 0,
    finances: groupedDomains.finances || 0,
    health: groupedDomains.health || 0,
    livingPlace: groupedDomains["living-place"] || 0
  }).map(pair => ({ domain: t(`sessionPart1.domainLabel.${pair[0]}`), value: pair[1] }))

  const categories = toPairs(groupBy(union(session.alters[0], session.alters[1]), 'alter_category')).map(pair => ({ category: t(`sessionPart1.reading.distribution.${pair[0]}`), value: pair[1].length }))

  return (
    <>
      <Descriptions layout="vertical" bordered column={1}>
        <Descriptions.Item label={t('sessionPart1.reading.networkStats')}>
          {t('sessionPart1.reading.nonProfessionals')} : {metrics.nonprofessionals}<br />
          {t('sessionPart1.reading.professionals')} : {metrics.professionals}<br />
          {t('sessionPart1.reading.total')} : {metrics.alters}<br />
          {t('sessionPart1.reading.majority')} {metrics.professionals > metrics.nonprofessionals ? t('sessionPart1.reading.professionals')?.toLowerCase() : t('sessionPart1.reading.nonProfessionals')?.toLowerCase()}
        </Descriptions.Item>
        <Descriptions.Item label={<>{t('sessionPart1.reading.distribution')} <Info content={t('sessionPart1.reading.distributionExplanation')} /></>}>
          <div style={{ maxWidth: 450, maxHeight: 200 }}>
            <Pie data={categories} angleField="value" colorField="category" height={200} radius={0.9} />
          </div>
        </Descriptions.Item>
        <Descriptions.Item label={t('sessionPart1.reading.domains')}>
          <div style={{ maxWidth: 500, maxHeight: 200 }}>
            <Pie data={domains} angleField="value" colorField="domain" height={200} radius={0.9} legend={{ offsetX: -50 }} />
          </div>
          {emptyDomains.length !== 0 && <div><br /><Icon icon="exclamation-triangle" color="#ffa940" /> {t('sessionPart1.reading.emptyGroups')} : {emptyDomains.join(', ')}</div>}
        </Descriptions.Item>
      </Descriptions>
      <br /><br />
      <List
        bordered
        dataSource={["psycho", "regular", "crisis", "largeHelp", "important"]}
        renderItem={item => (
          <List.Item onClick={() => {
            setShowFilters(true)
            setFilter(item)
            setTab("help")
          }} style={{ cursor: "pointer" }}>{t(`sessionPart1.tabs.alters.filters.${item}`)}</List.Item>
        )}
      />
    </>
  )

}