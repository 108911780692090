import React from 'react'

import Icon from './Icon'
import { useTranslation } from 'react-i18next'

import './Satisfaction.css'

import { Slider } from 'antd'



export default (props) => {

  const { t } = useTranslation()

  return (
    props.value === null || props.value === undefined || props.value > 7 || props.value < 1 ? null :
      <div className="icon-wrapper">
        <Icon icon="frown-open" />
        <Slider {...props} min={1} max={7} step={1} marks={{ [props.value]: t('satisfaction.' + props.value) }} disabled />
        <Icon icon="grin" />
      </div>
  )

}
