import React, { useContext } from 'react'

import Icon from './Icon'
import { LocaleContext } from '../contexts/LocaleContext'

import { Dropdown, Menu } from 'antd'



export default () => {

  const { setLocale } = useContext(LocaleContext)

  const handleLanguageChange = ({ key }) => {
    setLocale(key)
  }

  return (
    <Dropdown arrow overlay={<Menu onClick={handleLanguageChange}>
      <Menu.Item key='en'>English</Menu.Item>
      <Menu.Item key='fr'>Français</Menu.Item>
    </Menu>}>
      <Icon icon={['fal', 'language']} size="3x" style={{ position: 'absolute', right: 10, top: 5, cursor: 'pointer' }} />
    </Dropdown>
  )

}
