import React, { useState, useEffect, useContext } from 'react'

import { useTranslation } from 'react-i18next'

import { AuthContext } from '../contexts/AuthContext'
import { callApi } from '../services/fetchService'
import Icon from '../components/Icon'
import TableButton from '../components/TableButton'

import { PageHeader, Table, Tag, Button, Modal, Form, Input, Alert, Radio, Space, message } from 'antd'
const { Column } = Table

const { REACT_APP_WELCOME_LINK } = process.env



export default () => {

  const { t } = useTranslation()

  const auth = useContext(AuthContext)

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [editForm] = Form.useForm()
  const [currentUser, setCurrentUser] = useState(null)

  const [isInviteModalVisible, setIsInviteModalVisible] = useState(false)
  const [inviteForm] = Form.useForm()

  const loadUsers = () => {
    setLoading(true)
    callApi({
      endpoint: 'service/users',
      method: 'GET'
    })
      .then((data) => {
        setData(data)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
        message.error(t('users.unableToLoad'))
      })
  }

  useEffect(loadUsers, [])

  useEffect(() => {
    if (isInviteModalVisible) inviteForm.resetFields()
  }, [isInviteModalVisible, inviteForm])

  const inviteUser = (values) => {
    values.url = REACT_APP_WELCOME_LINK
    setLoading(true)
    callApi({
      endpoint: 'auth/invite',
      method: 'POST',
      body: values
    }).then((data) => {
      setLoading(false)
      setIsInviteModalVisible(false)
      loadUsers()
      message.success(t('users.inviteSent'))
    }).catch((error) => {
      setLoading(false)
      if (error.status === 409) {
        message.warning(t('users.emailUsed'))
      } else {
        console.log(error)
        message.error(t('users.unableToSendInvite'))
      }
    })
  }

  const openEditUserModal = user => {
    setCurrentUser(user)
    setIsEditModalVisible(true)
    editForm.setFieldsValue(user)
  }

  const editUser = (values) => {
    setLoading(true)
    callApi({
      endpoint: 'auth/user/' + currentUser.user_id,
      method: 'PUT',
      body: values
    }).then((data) => {
      setLoading(false)
      setIsEditModalVisible(false)
      loadUsers()
      message.success(t('users.updated'))
    }).catch((error) => {
      console.log(error)
      message.error(t('users.unableToUpdate'))
    })
  }

  const deleteUser = (user) => {
    Modal.confirm({
      title: t('users.deleteModal.title'),
      content: <><p style={{ marginTop: 25 }}><strong>{t('users.deleteModal.warning1')}</strong></p><p>{t('users.deleteModal.warning2')}</p></>,
      okText: <><Icon icon="trash" /> {t('misc.delete')}</>,
      okButtonProps: { danger: true },
      onOk: () => {
        callApi({
          endpoint: 'service/users',
          method: 'DELETE',
          body: { list: [user.user_id] }
        }).then((data) => {
          setLoading(false)
          loadUsers()
          message.success(t('users.deleted'))
        }).catch((error) => {
          setLoading(false)
          message.error(t('users.unableToDelete'))
        })
      }
    })
  }

  return (
    <>

      <PageHeader
        title={<><Icon icon="hospital-user" fixedWidth /> {t('users.title')}</>}
        extra={<Button icon={<Icon icon="paper-plane" style={{ marginRight: 10 }} />} onClick={() => setIsInviteModalVisible(true)}> {t('users.invite')}</Button>}
      />

      <Table dataSource={data} rowKey="user_id" loading={loading}>
        <Column title={t('users.columns.name')} render={user => <>{user.contact_name || '-'}{auth.user.user_id === user.user_id && <Tag style={{ marginLeft: 10 }}><Icon icon="smile-wink" /> {t('users.itIsYou')}</Tag>}</>} />
        <Column title={t('users.columns.email')} dataIndex="email" />
        <Column title={t('users.columns.status')} render={user => user.profile_created ? <Tag color="green"><Icon icon="check" fixedWidth /> {t('users.status.active')}</Tag> : <Tag color="blue"><Icon icon="envelope-open" fixedWidth /> {t('users.status.invited')}</Tag>} />
        <Column title={t('users.columns.permissions')} render={user => user.is_admin ? <Tag color="volcano"><Icon icon="user-shield" fixedWidth /> {t('users.permissions.admin')}</Tag> : <Tag color="blue"><Icon icon="user" fixedWidth /> {t('users.permissions.user')}</Tag>} />
        <Column title={t('users.columns.actions')} width={100} render={user =>
          auth.user.user_id !== user.user_id
            ? <Space>
              <TableButton icon="pencil" onClick={() => openEditUserModal(user)} />
              <TableButton icon="trash" danger onClick={() => deleteUser(user)} />
            </Space>
            : <></>
        } />
      </Table>

      <Modal
        title={<><Icon icon="paper-plane" style={{ marginRight: 10 }} /> {t('users.inviteModal.title')}</>}
        visible={isInviteModalVisible}
        okText={t('users.inviteModal.send')}
        okButtonProps={{ icon: <Icon icon="paper-plane" style={{ marginRight: 7 }} />, loading }}
        onOk={inviteForm.submit}
        onCancel={() => setIsInviteModalVisible(false)}
        destroyOnClose
      >
        <Alert showIcon message={t('users.inviteModal.alert')} style={{ marginBottom: 30 }} />
        <Form form={inviteForm} onFinish={inviteUser} layout="vertical">
          <Form.Item label={t('users.inviteModal.email')} name="email" rules={[
            { required: true, type: "email", message: t('users.inviteModal.emailRequired') }
          ]}>
            <Input prefix={<Icon icon="envelope" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={t('users.inviteModal.email')} disabled={loading} />
          </Form.Item>
          <Form.Item label={t('users.inviteModal.permissions')} name="is_admin" rules={[{ required: true, message: t('users.inviteModal.permissionsRequired') }]} initialValue={false}>
            <Radio.Group>
              <Space direction="vertical">
                <Radio value={false}>{t('users.permissions.user')}</Radio>
                <Radio value={true}>{t('users.permissions.admin')}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={<><Icon icon="pencil" style={{ marginRight: 10 }} /> {t('users.updateModal.title')} : {currentUser?.contact_name}</>}
        visible={isEditModalVisible}
        okText={t('misc.save')}
        okButtonProps={{ icon: <Icon icon="save" style={{ marginRight: 7 }} />, loading }}
        onOk={editForm.submit}
        onCancel={() => setIsEditModalVisible(false)}
        destroyOnClose
      >
        <Form form={editForm} onFinish={editUser} layout="vertical">
          <Form.Item label={t('users.updateModal.name')} name="contact_name" rules={[{ required: true, message: t('users.updateModal.nameRequired') }]}>
            <Input prefix={<Icon icon="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={t('users.updateModal.name')} disabled={loading} />
          </Form.Item>
          <Form.Item label={t('users.updateModal.permissions')} name="is_admin" rules={[{ required: true, message: t('users.updateModal.permissionsRequired') }]} initialValue={false}>
            <Radio.Group>
              <Space direction="vertical">
                <Radio value={false}>{t('users.permissions.user')}</Radio>
                <Radio value={true}>{t('users.permissions.admin')}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>

    </>
  )

}