import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { callApi } from '../services/fetchService'

import Icon from '../components/Icon'
import LanguageSelector from '../components/LanguageSelector'

import { Form, Input, Button, Result, message, Space, Divider } from 'antd'

const { REACT_APP_RESET_LINK } = process.env



export default () => {

  const { t } = useTranslation()

  const [isDone, setIsDone] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const send = (values) => {
    setIsLoading(true)
    values.url = REACT_APP_RESET_LINK
    callApi({ endpoint: '/auth/pw/req', method: 'PUT', body: values })
      .then(() => {
        setIsLoading(false)
        setIsDone(true)
      })
      .catch((error) => {
        console.log(error)
        setIsLoading(false)
        message.error(t('forgottenPassword.sendEmailError'))
      })
  }

  return (
    <div className="login-form">
      <LanguageSelector />
      <div className="login-form-header">
        <Icon icon={['fal', 'chart-network']} size="8x" fixedWidth />
        <h1>Egonet</h1>
        <Divider />
      </div>
      {isDone
        ? <Result
          title={t('forgottenPassword.doneTitle')}
          subTitle={t('forgottenPassword.doneContent')}
          status="success"
          extra={<Link to="/login"><Button icon={<Icon icon="lock" fixedWidth />} >&nbsp;&nbsp;{t('forgottenPassword.doneLogin')}</Button></Link>}
          style={{ paddingTop: 20 }}
          icon={<Icon icon="check-circle" />} />
        : <>
          <p style={{ textAlign: 'center' }}>{t('forgottenPassword.content')} <Icon icon="smile-wink" /></p>
          <Form onFinish={send} style={{ marginTop: 24 }}>
            <Form.Item name="email" rules={[{ required: true, message: t('forgottenPassword.requiredEmail') }, { type: "email", message: t('forgottenPassword.wrongEmailFormat') }]}>
              <Input prefix={<Icon icon="envelope" />} placeholder={t('forgottenPassword.email')} />
            </Form.Item>
            <Space direction="vertical" className="login-form-button">
              <Button type="primary" htmlType="submit" className="login-form-button" icon={<Icon icon="paper-plane" fixedWidth />} loading={isLoading}>&nbsp;&nbsp;{t('forgottenPassword.sendEmail')}</Button>
              <Link to="/login"><Button type="link" className="login-form-button" icon={<Icon icon="undo" fixedWidth />} >&nbsp;&nbsp;{t('forgottenPassword.login')}</Button></Link>
            </Space>
          </Form>
        </>}
    </div>
  )

}