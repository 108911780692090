import React from 'react'

import Icon from './Icon'

import { Popover } from 'antd'



const types = {
  info: {
    icon: "info-circle",
    iconColor: "#1890FF",
    marginLeft: "7px"
  },
  warning: {
    icon: "exclamation-triangle",
    iconColor: "#ffa940"
  }
}



export default (props) => {

  const type = types[props.type] || types["info"]

  return(
    <Popover content={
      <span style={{ fontSize: "11px" }}>{props.content}</span>
    }>
      <Icon style={{ cursor: "pointer", marginLeft: type.marginLeft || 0 }} icon={type.icon} color={type.iconColor} />
    </Popover>
  )

}
