import React from 'react'

import Icon from './Icon'


export default (props) => {

  return(
    (props.value === true || props.value === 'true') ? <Icon size="lg" icon="check" color="#52c41a" /> : <>-</>
  )

}
