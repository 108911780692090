import React from 'react'

import moment from 'moment'
import { t } from '../i18n'

import { Tag, Typography } from 'antd'
const { Text } = Typography



export const fullNameSorter = (a, b) => (a.lastname + ' ' + a.firstname).localeCompare(b.lastname + ' ' + b.firstname)
export const emailSorter = (a, b) => a.email.localeCompare(b.email)
export const dateSorter = (a, b) => moment(a.date).valueOf() - moment(b.date).valueOf()
export const pairingCode = device => {
  if (!device || !device.pairingCode || device.paired) return '-'
  let pc = device.pairingCode.toUpperCase()
  pc = `${pc.substring(0, 4)} - ${pc.substring(4, 8)} - ${pc.substring(8, 12)} - ${pc.substring(12, 16)}`
  if (moment(device.expiration).isAfter(moment())) {
    return <><Tag>{pc}</Tag> <small>{`(${t('tablets.expireOn')} ${moment(device.expiration).format('L')})`}</small></>
  }
  return <><Tag><Text delete>{pc}</Text></Tag> <small><Text type="danger">{t('tablets.expired')}</Text></small></>
}