import React from 'react'

import Icon from './Icon'


export default (props) => {

  return(
    <>
      { props.gender && (props.gender === 'h' || props.gender === 'm') && <Icon size="lg" icon="mars" /> }
      { props.gender && props.gender === 'f' && <Icon size="lg" icon="venus" /> }
      { props.gender && props.gender === 'i' && <Icon size="lg" icon="question" /> }
    </>
  )

}
